:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._height-10037{height:100%;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexDirection-row{flex-direction:row;}
:root ._flexGrow-1{flex-grow:1;}
:root ._alignItems-center{align-items:center;}
:root ._pt-t-space-spa1360334080{padding-top:var(--t-space-spacing12);}
:root ._pr-t-space-spa1360334080{padding-right:var(--t-space-spacing12);}
:root ._pb-t-space-spa1360334080{padding-bottom:var(--t-space-spacing12);}
:root ._pl-t-space-spa1360334080{padding-left:var(--t-space-spacing12);}
:root ._pl-t-space-spa1360333981{padding-left:var(--t-space-spacing48);}
:root ._alignContent-center{align-content:center;}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_none{display:none;}}