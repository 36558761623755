:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-gap1573{gap:var(--t-space-gap16);}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_10037{width:100%;}}
:root ._minHeight-44px{min-height:44px;}
:root ._minWidth-44px{min-width:44px;}
:root ._flexDirection-column{flex-direction:column;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-center{justify-content:center;}
:root ._borderTopLeftRadius-100000px{border-top-left-radius:100000px;}
:root ._borderTopRightRadius-100000px{border-top-right-radius:100000px;}
:root ._borderBottomRightRadius-100000px{border-bottom-right-radius:100000px;}
:root ._borderBottomLeftRadius-100000px{border-bottom-left-radius:100000px;}
:root ._pt-0px{padding-top:0px;}
:root ._pr-0px{padding-right:0px;}
:root ._pb-0px{padding-bottom:0px;}
:root ._pl-0px{padding-left:0px;}
:root ._width-44px{width:44px;}
:root ._height-44px{height:44px;}
:root ._maxWidth-44px{max-width:44px;}
:root ._maxHeight-44px{max-height:44px;}
:root ._backgroundColor-surface3{background-color:var(--surface3);}
:root ._flexGrow-1{flex-grow:1;}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_row{flex-direction:row;}}
@media (max-width: 640px){:root:root:root:root:root:root ._justifyContent-_md_space-betwe3241{justify-content:space-between;}}
@media (max-width: 640px){:root:root:root:root:root:root ._alignItems-_md_flex-start{align-items:flex-start;}}
:root ._mt-auto{margin-top:auto;}
:root ._mb-auto{margin-bottom:auto;}
:root ._mt-t-space-non101{margin-top:var(--t-space-none);}
:root ._mb-t-space-non101{margin-bottom:var(--t-space-none);}
:root ._alignItems-flex-start{align-items:flex-start;}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_column{flex-direction:column;}}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-gap52{gap:var(--t-space-gap4);}}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-gap1569{gap:var(--t-space-gap12);}}
:root ._gap-t-space-gap56{gap:var(--t-space-gap8);}
:root ._gap-2px{gap:2px;}