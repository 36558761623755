:root ._display-inline{display:inline;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._position-absolute{position:absolute;}
:root ._width-1px{width:1px;}
:root ._height-1px{height:1px;}
:root ._mt--1px{margin-top:-1px;}
:root ._mr--1px{margin-right:-1px;}
:root ._mb--1px{margin-bottom:-1px;}
:root ._ml--1px{margin-left:-1px;}
:root ._zIndex--10000{z-index:-10000;}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._opacity-1e-8{opacity:1e-8;}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}