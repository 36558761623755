:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-spa1360334080{gap:var(--t-space-spacing12);}
:root ._backgroundColor-surface2{background-color:var(--surface2);}
:root ._borderTopLeftRadius-t-radius-ro291586449{border-top-left-radius:var(--t-radius-rounded16);}
:root ._borderTopRightRadius-t-radius-ro291586449{border-top-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomRightRadius-t-radius-ro291586449{border-bottom-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomLeftRadius-t-radius-ro291586449{border-bottom-left-radius:var(--t-radius-rounded16);}
:root ._pt-t-space-pad1331704929{padding-top:var(--t-space-padding12);}
:root ._pr-t-space-pad1331704929{padding-right:var(--t-space-padding12);}
:root ._pb-t-space-pad1331704929{padding-bottom:var(--t-space-padding12);}
:root ._pl-t-space-pad1331704929{padding-left:var(--t-space-padding12);}
:root ._flexDirection-column{flex-direction:column;}
:root ._borderTopLeftRadius-t-radius-ro291586453{border-top-left-radius:var(--t-radius-rounded12);}
:root ._borderTopRightRadius-t-radius-ro291586453{border-top-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomRightRadius-t-radius-ro291586453{border-bottom-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomLeftRadius-t-radius-ro291586453{border-bottom-left-radius:var(--t-radius-rounded12);}
:root ._alignSelf-flex-start{align-self:flex-start;}
:root ._backgroundColor-statusWarni108953{background-color:var(--statusWarning2);}
:root ._backgroundColor-statusCriti3045924{background-color:var(--statusCritical2);}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._flexWrap-wrap{flex-wrap:wrap;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}