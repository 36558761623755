:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._pt-24px{padding-top:24px;}
:root ._pr-24px{padding-right:24px;}
:root ._pb-24px{padding-bottom:24px;}
:root ._pl-24px{padding-left:24px;}
:root ._alignItems-center{align-items:center;}
:root ._gap-t-space-spa1360334018{gap:var(--t-space-spacing32);}
:root ._gap-t-space-spa1360334080{gap:var(--t-space-spacing12);}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-row{flex-direction:row;}
:root ._height-10037{height:100%;}
:root ._width-10037{width:100%;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._pt-32px{padding-top:32px;}
:root ._pr-32px{padding-right:32px;}
:root ._pb-32px{padding-bottom:32px;}
:root ._pl-32px{padding-left:32px;}
:root ._justifyContent-center{justify-content:center;}
:root ._width--webkit-fil1784293720{width:-webkit-fill-available;}
:root ._flexGrow-1{flex-grow:1;}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}
:root ._width-4537{width:45%;}