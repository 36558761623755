:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-8px{gap:8px;}
:root ._justifyContent-flex-end{justify-content:flex-end;}
:root ._width-max-content{width:max-content;}
:root ._flexDirection-column{flex-direction:column;}
:root ._position-absolute{position:absolute;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._pb-14px{padding-bottom:14px;}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._width-8037{width:80%;}