:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._pt-20px{padding-top:20px;}
:root ._pb-20px{padding-bottom:20px;}
:root ._gap-t-space-spa1360334051{gap:var(--t-space-spacing20);}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._gap-t-space-spa1360334080{gap:var(--t-space-spacing12);}