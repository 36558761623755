:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._backgroundColor-surface1{background-color:var(--surface1);}
:root ._borderTopLeftRadius-t-radius-ro291586453{border-top-left-radius:var(--t-radius-rounded12);}
:root ._borderTopRightRadius-t-radius-ro291586453{border-top-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomRightRadius-t-radius-ro291586453{border-bottom-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomLeftRadius-t-radius-ro291586453{border-bottom-left-radius:var(--t-radius-rounded12);}
:root ._height-2px{height:2px;}
:root ._width-10px{width:10px;}