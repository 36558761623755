:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._position-absolute{position:absolute;}
:root ._top-16px{top:16px;}
:root ._left-16px{left:16px;}
:root ._position-relative{position:relative;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._gap-t-space-gap1569{gap:var(--t-space-gap12);}
:root ._width-10037{width:100%;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._justifyContent-center{justify-content:center;}
:root ._mr-t-space-spa94665589{margin-right:var(--t-space-spacing4);}