:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._borderTopColor-surface3{border-top-color:var(--surface3);}
:root ._borderRightColor-surface3{border-right-color:var(--surface3);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-surface3{border-left-color:var(--surface3);}
:root ._flexShrink-1{flex-shrink:1;}
:root ._borderTopWidth-0px{border-top-width:0px;}
:root ._borderRightWidth-0px{border-right-width:0px;}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderLeftWidth-0px{border-left-width:0px;}
:root ._flexGrow-1{flex-grow:1;}
:root ._height-0px{height:0px;}
:root ._maxHeight-0px{max-height:0px;}
:root ._mt-t-space-gap56{margin-top:var(--t-space-gap8);}
:root ._mb-t-space-gap56{margin-bottom:var(--t-space-gap8);}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._gap-t-space-gap56{gap:var(--t-space-gap8);}
:root ._mt-t-space-pad1331704925{margin-top:var(--t-space-padding16);}
:root ._mb-auto{margin-bottom:auto;}
:root ._flexWrap-wrap{flex-wrap:wrap;}
:root ._width-5537{width:55%;}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._pt-t-space-pad1331704929{padding-top:var(--t-space-padding12);}
:root ._pr-t-space-pad1331704929{padding-right:var(--t-space-padding12);}
:root ._pb-t-space-pad1331704929{padding-bottom:var(--t-space-padding12);}
:root ._pl-t-space-pad1331704929{padding-left:var(--t-space-padding12);}
:root ._borderTopLeftRadius-t-radius-ro291586453{border-top-left-radius:var(--t-radius-rounded12);}
:root ._borderTopRightRadius-t-radius-ro291586453{border-top-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomRightRadius-t-radius-ro291586453{border-bottom-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomLeftRadius-t-radius-ro291586453{border-bottom-left-radius:var(--t-radius-rounded12);}
:root ._justifyContent-center{justify-content:center;}
:root ._backgroundColor-statusCriti3045924{background-color:var(--statusCritical2);}
:root ._backgroundColor-surface3{background-color:var(--surface3);}
:root ._borderTopLeftRadius-t-radius-ro291586449{border-top-left-radius:var(--t-radius-rounded16);}
:root ._borderTopRightRadius-t-radius-ro291586449{border-top-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomRightRadius-t-radius-ro291586449{border-bottom-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomLeftRadius-t-radius-ro291586449{border-bottom-left-radius:var(--t-radius-rounded16);}
:root ._backgroundColor-surface2{background-color:var(--surface2);}
:root ._pt-t-space-gap1569{padding-top:var(--t-space-gap12);}
:root ._pb-t-space-gap1569{padding-bottom:var(--t-space-gap12);}
:root ._pr-t-space-gap1573{padding-right:var(--t-space-gap16);}
:root ._pl-t-space-gap1573{padding-left:var(--t-space-gap16);}
:root ._pr-t-space-gap1569{padding-right:var(--t-space-gap12);}
:root ._pl-t-space-gap1569{padding-left:var(--t-space-gap12);}