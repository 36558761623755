:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._flexGrow-1{flex-grow:1;}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._flexDirection-column{flex-direction:column;}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
:root ._gap-t-space-spa94665589{gap:var(--t-space-spacing4);}
:root ._width-9537{width:95%;}
:root ._alignItems-center{align-items:center;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._width-10037{width:100%;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}