:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-gap1569{gap:var(--t-space-gap12);}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._flexGrow-1{flex-grow:1;}
:root ._flexBasis-0px{flex-basis:0px;}
@media (max-width: 450px){:root:root:root:root:root:root:root ._flexBasis-_sm_auto{flex-basis:auto;}}
:root ._gap-t-space-gap1598{gap:var(--t-space-gap20);}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._width-5037{width:50%;}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_10037{width:100%;}}
:root ._pt-t-space-spa1360334076{padding-top:var(--t-space-spacing16);}
:root ._pb-t-space-spa1360334076{padding-bottom:var(--t-space-spacing16);}
:root ._pr-t-space-spa1360334047{padding-right:var(--t-space-spacing24);}
:root ._pl-t-space-spa1360334047{padding-left:var(--t-space-spacing24);}
:root ._borderBottomLeftRadius-t-radius-ro291586424{border-bottom-left-radius:var(--t-radius-rounded20);}
:root ._borderBottomRightRadius-t-radius-ro291586424{border-bottom-right-radius:var(--t-radius-rounded20);}
:root ._backgroundColor-surface2Hov3121676{background-color:var(--surface2Hovered);}
:root ._backgroundColor-surface2{background-color:var(--surface2);}
@media (max-width: 450px){:root:root:root:root:root:root:root ._flexDirection-_sm_column{flex-direction:column;}}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-center{justify-content:center;}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_none{display:none;}}
:root ._height-10037{height:100%;}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_row{flex-direction:row;}}
@media (max-width: 640px){:root:root:root:root:root:root ._justifyContent-_md_flex-start{justify-content:flex-start;}}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-gap1602{gap:var(--t-space-gap24);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._gap-_sm_t-space-gap52{gap:var(--t-space-gap4);}}
@media (max-width: 450px){:root:root:root:root:root:root:root ._width-_sm_10037{width:100%;}}
:root ._gap-t-space-gap56{gap:var(--t-space-gap8);}