:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._borderTopLeftRadius-t-radius-ro291586453{border-top-left-radius:var(--t-radius-rounded12);}
:root ._borderTopRightRadius-t-radius-ro291586453{border-top-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomRightRadius-t-radius-ro291586453{border-bottom-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomLeftRadius-t-radius-ro291586453{border-bottom-left-radius:var(--t-radius-rounded12);}
:root ._pb-t-space-pad1331704929{padding-bottom:var(--t-space-padding12);}
:root ._backgroundColor-surface2{background-color:var(--surface2);}
:root ._height-10037{height:100%;}
:root ._width-10037{width:100%;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._mt-t-space-spa1360334080{margin-top:var(--t-space-spacing12);}
:root ._pl-t-space-spa1360334080{padding-left:var(--t-space-spacing12);}
:root ._pr-t-space-spa1360334080{padding-right:var(--t-space-spacing12);}
:root ._height-12px{height:12px;}
:root ._width-120px{width:120px;}
:root ._height-16px{height:16px;}
:root ._width-80px{width:80px;}