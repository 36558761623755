:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._alignItems-center{align-items:center;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-10px{gap:10px;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._gap-t-space-spa94665587{gap:var(--t-space-spacing2);}
:root ._gap-t-space-spa1360334076{gap:var(--t-space-spacing16);}
:root ._gap-t-space-spa1360334080{gap:var(--t-space-spacing12);}
:root ._gap-t-space-gap1573{gap:var(--t-space-gap16);}
:root ._pt-t-space-spa94665589{padding-top:var(--t-space-spacing4);}
:root ._pb-t-space-spa94665589{padding-bottom:var(--t-space-spacing4);}
:root ._flexGrow-1{flex-grow:1;}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_column{flex-direction:column;}}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._opacity-0--5{opacity:0.5;}
:root ._pointerEvents-auto{pointer-events:auto !important;}
:root ._opacity-1{opacity:1;}
:root ._pt-t-space-spa1360334080{padding-top:var(--t-space-spacing12);}
:root ._pb-t-space-spa1360334080{padding-bottom:var(--t-space-spacing12);}
:root ._pr-t-space-spa1360334076{padding-right:var(--t-space-spacing16);}
:root ._pl-t-space-spa1360334076{padding-left:var(--t-space-spacing16);}
:root ._borderTopLeftRadius-t-radius-ro291586453{border-top-left-radius:var(--t-radius-rounded12);}
:root ._borderTopRightRadius-t-radius-ro291586453{border-top-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomRightRadius-t-radius-ro291586453{border-bottom-right-radius:var(--t-radius-rounded12);}
:root ._borderBottomLeftRadius-t-radius-ro291586453{border-bottom-left-radius:var(--t-radius-rounded12);}
:root ._borderTopWidth-t-space-spa94665586{border-top-width:var(--t-space-spacing1);}
:root ._borderRightWidth-t-space-spa94665586{border-right-width:var(--t-space-spacing1);}
:root ._borderBottomWidth-t-space-spa94665586{border-bottom-width:var(--t-space-spacing1);}
:root ._borderLeftWidth-t-space-spa94665586{border-left-width:var(--t-space-spacing1);}
:root ._borderTopColor-surface3{border-top-color:var(--surface3);}
:root ._borderRightColor-surface3{border-right-color:var(--surface3);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-surface3{border-left-color:var(--surface3);}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._gap-t-space-gap56{gap:var(--t-space-gap8);}
:root ._justifyContent-center{justify-content:center;}
:root ._borderTopLeftRadius-t-radius-ro840690004{border-top-left-radius:var(--t-radius-rounded6);}
:root ._borderTopRightRadius-t-radius-ro840690004{border-top-right-radius:var(--t-radius-rounded6);}
:root ._borderBottomRightRadius-t-radius-ro840690004{border-bottom-right-radius:var(--t-radius-rounded6);}
:root ._borderBottomLeftRadius-t-radius-ro840690004{border-bottom-left-radius:var(--t-radius-rounded6);}
:root ._backgroundColor-surface3{background-color:var(--surface3);}
:root ._pr-7px{padding-right:7px;}
:root ._pl-7px{padding-left:7px;}
:root ._pt-2px{padding-top:2px;}
:root ._pb-2px{padding-bottom:2px;}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_none{display:none;}}
:root ._gap-t-space-gap1569{gap:var(--t-space-gap12);}