:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._width-10037{width:100%;}
:root ._flexWrap-nowrap{flex-wrap:nowrap;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._gap-4px{gap:4px;}