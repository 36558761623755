:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-130px{width:130px;}
:root ._flexGrow-0{flex-grow:0;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._gap-8px{gap:8px;}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_10037{width:100%;}}
:root ._flexShrink-0{flex-shrink:0;}
:root ._gap-5px{gap:5px;}
:root ._maxWidth-100vw{max-width:100vw;}
:root ._width-10037{width:100%;}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}
:root ._pt-t-space-non101{padding-top:var(--t-space-none);}
:root ._pr-t-space-spa1360333981{padding-right:var(--t-space-spacing48);}
:root ._pl-t-space-spa1360333981{padding-left:var(--t-space-spacing48);}
:root ._pb-40px{padding-bottom:40px;}
@media (max-width: 768px){:root:root:root:root:root ._pr-_lg_t-space-spa1360333989{padding-right:var(--t-space-spacing40);}}
@media (max-width: 768px){:root:root:root:root:root ._pl-_lg_t-space-spa1360333989{padding-left:var(--t-space-spacing40);}}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._gap-t-space-spa1360334018{gap:var(--t-space-spacing32);}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_column{flex-direction:column;}}
:root ._height-10037{height:100%;}
:root ._gap-t-space-spa1360333927{gap:var(--t-space-spacing60);}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_none{display:none;}}
:root ._gap-t-space-spa1360334076{gap:var(--t-space-spacing16);}
@media (max-width: 640px){:root:root:root:root:root:root ._width-_md_auto{width:auto;}}
:root ._display-none{display:none;}
@media (max-width: 640px){:root:root:root:root:root:root ._display-_md_flex{display:flex;}}
:root ._borderTopColor-surface3{border-top-color:var(--surface3);}
:root ._borderRightColor-surface3{border-right-color:var(--surface3);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-surface3{border-left-color:var(--surface3);}
:root ._borderTopWidth-0px{border-top-width:0px;}
:root ._borderRightWidth-0px{border-right-width:0px;}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderLeftWidth-0px{border-left-width:0px;}
:root ._flexGrow-1{flex-grow:1;}
:root ._height-0px{height:0px;}
:root ._maxHeight-0px{max-height:0px;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._alignItems-center{align-items:center;}
@media (max-width: 640px){:root:root:root:root:root:root ._alignItems-_md_flex-start{align-items:flex-start;}}