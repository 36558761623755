:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._flexGrow-1{flex-grow:1;}
:root ._alignItems-center{align-items:center;}
:root ._gap-t-space-spa1360334080{gap:var(--t-space-spacing12);}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._flexDirection-column{flex-direction:column;}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._minHeight-20px{min-height:20px;}
:root ._alignItems-flex-end{align-items:flex-end;}