:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._borderTopLeftRadius-12px{border-top-left-radius:12px;}
:root ._borderTopRightRadius-12px{border-top-right-radius:12px;}
:root ._borderBottomRightRadius-12px{border-bottom-right-radius:12px;}
:root ._borderBottomLeftRadius-12px{border-bottom-left-radius:12px;}
:root ._borderTopWidth-t-space-spa94665586{border-top-width:var(--t-space-spacing1);}
:root ._borderRightWidth-t-space-spa94665586{border-right-width:var(--t-space-spacing1);}
:root ._borderBottomWidth-t-space-spa94665586{border-bottom-width:var(--t-space-spacing1);}
:root ._borderLeftWidth-t-space-spa94665586{border-left-width:var(--t-space-spacing1);}
:root ._height-24px{height:24px;}
:root ._position-absolute{position:absolute;}
:root ._width-24px{width:24px;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}