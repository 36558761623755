:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-100vw{width:100vw;}
:root ._height-100vh{height:100vh;}
:root ._alignItems-center{align-items:center;}
:root ._width-10037{width:100%;}
:root ._pt-t-space-spa94665586{padding-top:var(--t-space-spacing1);}
:root ._pr-t-space-spa94665586{padding-right:var(--t-space-spacing1);}
:root ._pb-t-space-spa94665586{padding-bottom:var(--t-space-spacing1);}
:root ._pl-t-space-spa94665586{padding-left:var(--t-space-spacing1);}
:root ._maxWidth-500px{max-width:500px;}
:root ._justifyContent-center{justify-content:center;}
:root ._mt-auto{margin-top:auto;}
:root ._mr-auto{margin-right:auto;}
:root ._mb-auto{margin-bottom:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._gap-t-space-gap1602{gap:var(--t-space-gap24);}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-gap1569{gap:var(--t-space-gap12);}
:root ._gap-t-space-gap56{gap:var(--t-space-gap8);}
:root ._backgroundColor-surface2{background-color:var(--surface2);}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}
:root ._pt-t-space-spa1360334047{padding-top:var(--t-space-spacing24);}
:root ._pr-t-space-spa1360334047{padding-right:var(--t-space-spacing24);}
:root ._pb-t-space-spa1360334047{padding-bottom:var(--t-space-spacing24);}
:root ._pl-t-space-spa1360334047{padding-left:var(--t-space-spacing24);}
:root ._borderTopLeftRadius-t-radius-ro291586420{border-top-left-radius:var(--t-radius-rounded24);}
:root ._borderTopRightRadius-t-radius-ro291586420{border-top-right-radius:var(--t-radius-rounded24);}
:root ._borderBottomRightRadius-t-radius-ro291586420{border-bottom-right-radius:var(--t-radius-rounded24);}
:root ._borderBottomLeftRadius-t-radius-ro291586420{border-bottom-left-radius:var(--t-radius-rounded24);}
:root ._gap-t-space-gap1573{gap:var(--t-space-gap16);}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._mt-spacing12{margin-top:spacing12;}
:root ._mb-spacing12{margin-bottom:spacing12;}