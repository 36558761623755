:root ._display-flex{display:flex;}
:root ._alignItems-center{align-items:center;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-100vw{width:100vw;}
:root ._position-absolute{position:absolute;}
:root ._justifyContent-center{justify-content:center;}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._top-0px{top:0px;}
:root ._right-0px{right:0px;}
:root ._bottom-0px{bottom:0px;}
:root ._left-0px{left:0px;}
:root ._pointerEvents-none{pointer-events:none !important;}
:root ._contain-strict{contain:strict;}