:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._height-12px{height:12px;}
:root ._width-10037{width:100%;}
:root ._justifyContent-flex-end{justify-content:flex-end;}
:root ._backgroundColor-surface1{background-color:var(--surface1);}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._maxWidth-68px{max-width:68px;}