:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-10037{width:100%;}
:root ._pt-t-space-spa1360333981{padding-top:var(--t-space-spacing48);}
:root ._pb-t-space-spa1360333981{padding-bottom:var(--t-space-spacing48);}
:root ._pr-t-space-spa1360333989{padding-right:var(--t-space-spacing40);}
:root ._pl-t-space-spa1360333989{padding-left:var(--t-space-spacing40);}
:root ._maxWidth-650px{max-width:650px;}
:root ._mt-t-space-spa1360333989{margin-top:var(--t-space-spacing40);}
:root ._borderTopLeftRadius-t-radius-ro291586424{border-top-left-radius:var(--t-radius-rounded20);}
:root ._borderTopRightRadius-t-radius-ro291586424{border-top-right-radius:var(--t-radius-rounded20);}
:root ._borderBottomRightRadius-t-radius-ro291586424{border-bottom-right-radius:var(--t-radius-rounded20);}
:root ._borderBottomLeftRadius-t-radius-ro291586424{border-bottom-left-radius:var(--t-radius-rounded20);}
:root ._borderTopColor-surface3{border-top-color:var(--surface3);}
:root ._borderRightColor-surface3{border-right-color:var(--surface3);}
:root ._borderBottomColor-surface3{border-bottom-color:var(--surface3);}
:root ._borderLeftColor-surface3{border-left-color:var(--surface3);}
:root ._borderTopWidth-t-space-spa94665586{border-top-width:var(--t-space-spacing1);}
:root ._borderRightWidth-t-space-spa94665586{border-right-width:var(--t-space-spacing1);}
:root ._borderBottomWidth-t-space-spa94665586{border-bottom-width:var(--t-space-spacing1);}
:root ._borderLeftWidth-t-space-spa94665586{border-left-width:var(--t-space-spacing1);}
:root ._pt-t-space-spa1360334047{padding-top:var(--t-space-spacing24);}
:root ._pr-t-space-spa1360334047{padding-right:var(--t-space-spacing24);}
:root ._pb-t-space-spa1360334047{padding-bottom:var(--t-space-spacing24);}
:root ._pl-t-space-spa1360334047{padding-left:var(--t-space-spacing24);}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._flexDirection-row{flex-direction:row;}
:root ._gap-t-space-gap1573{gap:var(--t-space-gap16);}
:root ._mt-t-space-spa1360334080{margin-top:var(--t-space-spacing12);}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_column{flex-direction:column;}}
:root ._mt-t-space-gap1569{margin-top:var(--t-space-gap12);}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._pt-t-space-pad1331704925{padding-top:var(--t-space-padding16);}
:root ._pr-t-space-pad1331704925{padding-right:var(--t-space-padding16);}
:root ._pb-t-space-pad1331704925{padding-bottom:var(--t-space-padding16);}
:root ._pl-t-space-pad1331704925{padding-left:var(--t-space-padding16);}
:root ._borderTopLeftRadius-t-radius-ro291586449{border-top-left-radius:var(--t-radius-rounded16);}
:root ._borderTopRightRadius-t-radius-ro291586449{border-top-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomRightRadius-t-radius-ro291586449{border-bottom-right-radius:var(--t-radius-rounded16);}
:root ._borderBottomLeftRadius-t-radius-ro291586449{border-bottom-left-radius:var(--t-radius-rounded16);}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-gap1573{gap:var(--t-space-gap16);}}
@media (max-width: 640px){:root:root:root:root:root:root ._alignItems-_md_flex-start{align-items:flex-start;}}
@media (max-width: 640px){:root:root:root:root:root:root ._justifyContent-_md_space-betwe3241{justify-content:space-between;}}
@media (max-width: 640px){:root:root:root:root:root:root ._flexDirection-_md_row-reverse{flex-direction:row-reverse;}}
@media (max-width: 640px){:root:root:root:root:root:root ._gap-_md_t-space-gap56{gap:var(--t-space-gap8);}}
@media (max-width: 640px){:root:root:root:root:root:root ._alignItems-_md_center{align-items:center;}}