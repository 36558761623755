:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-10px{gap:10px;}
:root ._pt-t-space-spa1360334080{padding-top:var(--t-space-spacing12);}
:root ._pb-t-space-spa1360334018{padding-bottom:var(--t-space-spacing32);}
:root ._pr-t-space-spa1360334047{padding-right:var(--t-space-spacing24);}
:root ._pl-t-space-spa1360334047{padding-left:var(--t-space-spacing24);}
:root ._gap-t-space-spa1360334047{gap:var(--t-space-spacing24);}