:root ._display-flex{display:flex;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-space-betwe3241{justify-content:space-between;}
:root ._alignItems-center{align-items:center;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._gap-t-space-gap52{gap:var(--t-space-gap4);}
:root ._height-36px{height:36px;}
:root ._gap-t-space-gap1602{gap:var(--t-space-gap24);}
:root ._width-10037{width:100%;}
:root ._width-min-content{width:min-content;}
:root ._alignSelf-stretch{align-self:stretch;}