:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._pt-t-space-spa94665589{padding-top:var(--t-space-spacing4);}
:root ._pr-t-space-spa94665589{padding-right:var(--t-space-spacing4);}
:root ._pb-t-space-spa94665589{padding-bottom:var(--t-space-spacing4);}
:root ._pl-t-space-spa94665589{padding-left:var(--t-space-spacing4);}
:root ._background-statusSucce114130{background:var(--statusSuccess2);}
:root ._borderTopWidth-t-space-spa94665586{border-top-width:var(--t-space-spacing1);}
:root ._borderRightWidth-t-space-spa94665586{border-right-width:var(--t-space-spacing1);}
:root ._borderBottomWidth-t-space-spa94665586{border-bottom-width:var(--t-space-spacing1);}
:root ._borderLeftWidth-t-space-spa94665586{border-left-width:var(--t-space-spacing1);}
:root ._borderTopStyle-dashed{border-top-style:dashed;}
:root ._borderRightStyle-dashed{border-right-style:dashed;}
:root ._borderBottomStyle-dashed{border-bottom-style:dashed;}
:root ._borderLeftStyle-dashed{border-left-style:dashed;}
:root ._borderTopColor-statusSucce3680{border-top-color:var(--statusSuccess);}
:root ._borderRightColor-statusSucce3680{border-right-color:var(--statusSuccess);}
:root ._borderBottomColor-statusSucce3680{border-bottom-color:var(--statusSuccess);}
:root ._borderLeftColor-statusSucce3680{border-left-color:var(--statusSuccess);}
:root ._borderTopLeftRadius-t-radius-ro840690002{border-top-left-radius:var(--t-radius-rounded8);}
:root ._borderTopRightRadius-t-radius-ro840690002{border-top-right-radius:var(--t-radius-rounded8);}
:root ._borderBottomRightRadius-t-radius-ro840690002{border-bottom-right-radius:var(--t-radius-rounded8);}
:root ._borderBottomLeftRadius-t-radius-ro840690002{border-bottom-left-radius:var(--t-radius-rounded8);}
:root ._cursor-pointer{cursor:pointer;}