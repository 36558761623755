:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._flexDirection-column{flex-direction:column;}
:root ._backgroundColor-neutral3{background-color:var(--neutral3);}
:root ._height-t-size-spac3237010{height:var(--t-size-spacing4);}
:root ._width-t-size-spac100347329{width:var(--t-size-spacing32);}
:root ._borderTopLeftRadius-t-radius-ro1041013639{border-top-left-radius:var(--t-radius-roundedFull);}
:root ._borderTopRightRadius-t-radius-ro1041013639{border-top-right-radius:var(--t-radius-roundedFull);}
:root ._borderBottomRightRadius-t-radius-ro1041013639{border-bottom-right-radius:var(--t-radius-roundedFull);}
:root ._borderBottomLeftRadius-t-radius-ro1041013639{border-bottom-left-radius:var(--t-radius-roundedFull);}
:root ._height-10037{height:100%;}
:root ._width-10037{width:100%;}
:root ._gap-t-space-spa94665593{gap:var(--t-space-spacing8);}